import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'



class LumberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {enterCustomCosts: false}
  }

  render() {
    const boardLengthElements = this.props.boardLengths.map(boardLength => {
      let costField = <div/>
      if (this.state.enterCustomCosts) {
        costField = <Col sm="3">
          <Form.Group>
            <Form.Label>Cost</Form.Label>
            <Form.Control type="number" value={boardLength.cost} onChange={e => {this.props.boardLengthCostUpdated(boardLength, parseInt(e.target.value))}} />
          </Form.Group>
        </Col>
      }
      return (
      <Form.Row>
        <Col sm="3">
          <Form.Group>
            <Form.Label >Length</Form.Label>
            <Form.Control type="number" value={boardLength.length} onChange={e => {this.props.boardLengthLengthUpdated(boardLength, parseInt(e.target.value))}} />
          </Form.Group>
        </Col>
        <Col sm="2">
          <Form.Group>
            <Form.Label>Unit</Form.Label>
            <Form.Control as="select">
              <option>ft</option>
            </Form.Control>
          </Form.Group>
        </Col>
        {costField}
        <Col sm="2">
          <Form.Group>
            <Form.Label><p/></Form.Label>
            <Button variant="danger" onClick={() => this.props.boardLengthRemoved(boardLength)}>
              Remove
            </Button>
          </Form.Group>
        </Col>
      </Form.Row>
    )
    })

    return (
      <div>
        <h3> What board lengths are available? </h3>
        <Form>
          {boardLengthElements}
          <Form.Row>
            <Col sm="2.5">
              <Button onClick={this.props.boardLengthAdded} variant="secondary">
                Add board length
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    );
  }
}

export default LumberForm;
