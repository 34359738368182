import React, { PureComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import './BackForward.component.scss';

class BackForward extends React.PureComponent {
  getArrowClassName(disabled) {
    if (disabled) {
      return 'arrow-disabled'
    }
    return 'arrow'
  }

  getClickHandler(disabled, clickHandler) {
    return () => {
      if (!disabled) {
        clickHandler()
      }
    }
  }

  render() {
    return (
      <div className='back-forward-container'>
        <FontAwesomeIcon className={this.getArrowClassName(!this.props.canGoBack)} icon={faArrowLeft} onClick={this.getClickHandler(!this.props.canGoBack, this.props.onBack)}/>
        <FontAwesomeIcon className={this.getArrowClassName(!this.props.canGoForward)} icon={faArrowRight} onClick={this.getClickHandler(!this.props.canGoForward,this.props.onForward)}/>
      </div>)
    }
}

export default BackForward;
