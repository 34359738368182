import React, { Component } from 'react';
import CutsForm from './CutsForm'
import BuyingRecommendation from './BuyingRecommendation'
import CutPlan from './CutPlan'
import LumberForm from './LumberForm'
import './LumberPurchaseOptimizer.scss';
import Button from 'react-bootstrap/Button'
import BackForward from '../utility-components/BackForward.component'


class LumberPurchaseOptimizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOutput: false,
      outputStale: true,
      boardLengths: [
        {length: 4, cost: this.getDefaultCostForLength(4)},
        {length: 6, cost: this.getDefaultCostForLength(6)},
        {length: 8, cost: this.getDefaultCostForLength(8)}
      ],
      cuts: [{length: 12, count: 1}]
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setStateFromInput(state) {
    this.setState({...state, outputStale: true})
    this.validate()
  }

  validate() {
    let validationError;
    const cuts = this.state.cuts.flatMap(cut => {
      const array = new Array(cut.count ? cut.count : 0);
      array.fill(cut.length);
      return array;
    });
    if (cuts.length > 19) {
      validationError = "We can only currently handle optimizing up to 19 cuts at a time."
    }
    this.setState({'validationError': validationError})
  }

  handleSubmit(event) {
    event.preventDefault();

    const cuts = this.state.cuts.flatMap(cut => {
      const array = new Array(cut.count ? cut.count : 0);
      array.fill(cut.length);
      return array;
    })

    const request = {
      cuts: cuts,
      availableLumber: this.state.boardLengths
    }

    fetch('https://vdycq2ugg5.execute-api.us-east-1.amazonaws.com/beta/optimizedlumberpurchase', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        timeout: 0,
        body: JSON.stringify(request)
    })
    .then(response => response.json())
    .then(data => {
      this.state.cutPlan = data.cutPlan;
      this.state.showOutput = true;
      this.state.outputStale = false;
      this.setState(this.state);
    });
  }

  getDefaultCostForLength(length) {
    return length - length/10;
  }

  render() {

    const inputElement =
    <div className="lumber-picker-cell">
      <BackForward className='nav' canGoBack={false} canGoForward={!this.state.outputStale} onForward={()=>this.setState({'showOutput': true})}/>
      <div className='lumber-picker-component-container content1'>
          <LumberForm
            boardLengths={this.state.boardLengths}
            boardLengthRemoved={(removedBoardLength) => {this.state.boardLengths.splice(this.state.boardLengths.indexOf(removedBoardLength),1); this.setStateFromInput(this.state)}}
            boardLengthAdded={() => {this.state.boardLengths.push({length: 1, cost: this.getDefaultCostForLength(1)}); this.setStateFromInput(this.state)}}
            boardLengthCostUpdated = {(boardLength, newCost) => {boardLength.cost = newCost; this.setStateFromInput(this.state)}}
            boardLengthLengthUpdated = {(boardLength, newLength) => {boardLength.length = newLength; boardLength.cost = this.getDefaultCostForLength(newLength); this.setStateFromInput(this.state)}}
          />
        </div>
        <div className='lumber-picker-component-container content2'>
          <CutsForm
            cuts = {this.state.cuts}
            cutRemoved = {(removedCut) => {this.state.cuts.splice(this.state.cuts.indexOf(removedCut),1); this.setStateFromInput(this.state)}}
            cutAdded = {() => {this.state.cuts.push({length: 12, count: 1}); this.setStateFromInput(this.state)}}
            cutCountUpdated = {(cut, newCount) => {cut.count = newCount; this.setStateFromInput(this.state)}}
            cutLengthUpdated = {(cut, newLength) => {cut.length = newLength; this.setStateFromInput(this.state)}}
          />
        </div>
        <div className='controls'>
          <span className='alert-slot'>{this.state.validationError}</span>
          <Button disabled={this.state.validationError} className="btn-test" onClick={this.handleSubmit} variant="primary">
            Submit
          </Button>
        </div>
      </div>

    const outputElement = !this.state.cutPlan ? <div/> : <div>
      <div className="lumber-picker-cell">
        <BackForward className='nav' canGoBack={true} canGoForward={false} onBack={()=>this.setState({'showOutput':false})}/>
        <div className='lumber-picker-component-container content1'>
          <BuyingRecommendation cutPlan = {this.state.cutPlan}/>
        </div>
        <div className='lumber-picker-component-container content2'>
          <CutPlan cutPlan = {this.state.cutPlan}/>
        </div>
      </div>
    </div>

    const content = this.state.showOutput ? outputElement : inputElement;

    return (
      <div>
        <div>
          <h2> Lumber Picker </h2>
          <p>For most projects, you will have a few different options when it comes to the board lengths made available by your lumber supplier. Choosing which lengths to purchase and how many of them you will need to complete your project while saving as much money as possible is not always easy. This tool is here to solve this problem for you.
          </p>
        </div>
        {content}
      </div>
    );
  }
}

export default LumberPurchaseOptimizer;
