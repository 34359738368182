import React, { PureComponent } from 'react';

class CutPlan extends React.PureComponent {

  render() {
    const cutPlan = this.props.cutPlan ? this.props.cutPlan : []
    const cutPlanElements = cutPlan.map((boardCutPlan, index) =>
      <div key={index}>
        <li> Get a {boardCutPlan.key} foot board. </li>
        <ul className="square">
        {boardCutPlan.value.map(cut =>
          <li> Make a {cut} inch cut.</li>
        )}
        </ul>
      </div>
    )

    return (
      <div>
        <h3>This is how you should cut them.</h3>
        <ul className="roman">
          {cutPlanElements}
        </ul>
      </div>)
    }
  }

export default CutPlan;
