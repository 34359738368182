import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import './CutsForm.css'


class CutsForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const cutElements = this.props.cuts.map(cut =>
      <Form.Row>
        <Col sm="3">
          <Form.Group>
            <Form.Label >Length</Form.Label>
            <Form.Control type="number" value={cut.length} onChange={e => {this.props.cutLengthUpdated(cut, parseInt(e.target.value))}} />
          </Form.Group>
        </Col>
        <Col sm="2">
          <Form.Group>
            <Form.Label>Unit</Form.Label>
            <Form.Control as="select">
              <option>in</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm="3">
          <Form.Group>
            <Form.Label>Count</Form.Label>
            <Form.Control type="number" value={cut.count} onChange={e => {this.props.cutCountUpdated(cut, parseInt(e.target.value))}} />
          </Form.Group>
        </Col>
        <Col sm="2">
          <Form.Group>
            <Form.Label><p/></Form.Label>
            <Button variant="danger" onClick={() => this.props.cutRemoved(cut)}>
              Remove
            </Button>
          </Form.Group>
        </Col>
      </Form.Row>
    )

    return (
      <div className='cutFormContainer'>
        <h3> What cut lengths do you need and how many? </h3>
        <Form>
          {cutElements}
          <Form.Row>
            <Col sm="2.5">
              <Button onClick={this.props.cutAdded} variant="secondary">
                Add cut
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </div>
    );
  }
}

export default CutsForm;
