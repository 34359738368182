import React, { PureComponent } from 'react';
const ReactMarkdown = require('react-markdown')


class Builds extends React.PureComponent {

  render() {
    const markdown = "## A Real Wicked Night Stand Build \n\
### Tools \n\
* Power Drill \n\
	* Philips head bit \n\
	* 6\" X ¼\" twist drill bit \n\
* Pocket Hole Jig \n\
* Table Saw \n\
	* Standard blade (the one that came with it) \n\
	* Dado blade (optional but recommended) \n\
* Sander \n\
	*  120 grit paper \n\
	*  220 grit paper \n\
* Miter Saw (optional but recommended) \n\
### Materials \n\
* 1 ¼\“ hardwood pocket hole screws \n\
* 1  \n\
### Through Cuts \n\
#### 1\” X 3\” Planks \n\
* 4 @ 24\” \n\
* 4 @ 13\” \n\
* 4 @ 16 ½\” \n\
#### ¼\” Paneling \n\
* 2 @ 17\” X 22\” \n\
* 1 @ 13.5\” X 19\” \n\
#### ¾\“ Top Plate \n\
* 1 @ 19\n X 19 ½";

    return (
      <div>
        <ReactMarkdown source={markdown} />
      </div>)
    }
  }

export default Builds;
