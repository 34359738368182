import React, { PureComponent } from 'react';

class BuyingRecommendation extends React.PureComponent {

  render() {
    const cutPlan = this.props.cutPlan ? this.props.cutPlan : []
    const buyList = cutPlan.map(board => board.key)
    const accumulatedBoards = buyList.reduce(
      (totals, p) => ({ ...totals, [p]: (totals[p] || 0) + 1 }),{});

    const buyElements = []
    if (accumulatedBoards) {
      Object.keys(accumulatedBoards).forEach(b => buyElements.push(
        <div> {accumulatedBoards[b]} board(s) at {b} feet</div>
      ));
    }

    return (
      <div>
        <h3>These are the boards you should buy.</h3>
        {buyElements}
      </div>)
    }
  }

export default BuyingRecommendation;
