import React, { Component } from 'react';
import logo from './logo.svg';
import './App.scss';
import LumberPurchaseOptimizer from './components/LumberPurchaseOptimizer'
import Builds from './components/Builds'
import LandingPage from './components/LandingPage'
import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import mainLogo from'./icon/wickedIcon.png';


class App extends Component {

  constructor(props) {
    super(props);
    const isMobile = !window.matchMedia( "(min-width: 700px)" ).matches;
    this.state = {
      'isMobile': isMobile,
      'isMenuCollapsed': isMobile
    };
  }

  render() {
    const containerClass = this.state.isMenuCollapsed ? 'app-container-collapsed-menu' : 'app-container-expanded-menu'
    const mainContentClickHandler = this.state.isMobile ? () => this.setState({'isMenuCollapsed': true}) : ()=>{}

    return (
      <Router>
        <div className={containerClass}>
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
            integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
            crossOrigin="anonymous"
          />
          <div className="header-container">
            <span onClick={() => this.setState({'isMenuCollapsed': !this.state.isMenuCollapsed})}>
              <FontAwesomeIcon icon={faBars} />
            </span>
              <span className="logo">
                <NavLink to={`/`}>
                  <img  src={mainLogo} height="32" width="32"/>
                  <span className="title">WICKED WOODWORKER</span>
                </NavLink>
              </span>
          </div>
          <div className="nav-container">
            <NavLink className="nav-link" activeClassName='active-nav-link' to={`/lumberPicker`}>Lumber Picker</NavLink>
            <NavLink className="nav-link" activeClassName='active-nav-link' to={`/builds`}>Builds</NavLink>
          </div>
          <div className="main-container" onClick={mainContentClickHandler}>
            <Switch>
              <Route exact path='/lumberPicker' component={LumberPurchaseOptimizer} />
              <Route exact path='/builds' component={Builds} />
              <Route exact path='/' component={LandingPage} />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
