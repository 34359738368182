import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom'

class LandingPage extends React.PureComponent {

  render() {
    return (
      <div>
        <h2>Welcome to Wicked Woodworker!</h2>
        <br/>
        <div>
          <p>
            If you are looking for project ideas or designs, check out our list of builds.
          </p>
          <NavLink to={`/builds`}>Builds</NavLink>
        </div>
        <br/>
        <br/>
        <div>
          <p>
            If you already know what you want to build and just need help buying lumber in the most cost effective way, checkout
            our lumber picking tool.
          </p>
          <NavLink to={`/lumberPicker`}>Lumber Picker</NavLink>
        </div>

      </div>
    )
  }

}

export default LandingPage;
